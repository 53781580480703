import React from "react";
import styled from "styled-components";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import H from "../../components/Headline";
import RelatedContentCardFlexBig from "../../components/RelatedContentCardFlexBig";

const StyledMiddle = styled.div`
  h1 {
    text-align: center;
  }

  .cardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  margin: 0vmin 1vmin;
  .container {
  }

  .related-content-card {
    max-width: 27%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
  @media all and (max-width: 900px) {
    .related-content-card {
      max-width: 43%;
    }
  }

  @media all and (max-width: 500px) {
    .cardcontainer {
      flex-direction: column;
      align-items: center;
    }

    .related-content-card {
      max-width: 95%;
      min-width: 80%;
    }
  }
`;

const breadCrumbLevels = {
  Home: "/de",
  Services: "/de/services"
};

// Hreflang data

const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/leistungen"
  },
  {
    hreflang: "da",
    href: "/da/services"
  },
  {
    hreflang: "en",
    href: "/en/services"
  },
  {
    hreflang: "x-default",
    href: "/en/services"
  }
];

const Services = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Freelance Services"
        lang="de"
        description="Leistungen zu den Bereichen Analytics, SEO, Tag Management und mehr. Kontaktieren Sie uns für ein individuelles Angebot!"
        alternateLangs={alternateLangs}
      />
      <MainContent maxWidth="1000">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <H as="h1">Ich biete Hilfe zu...</H>
            <div className="cardcontainer">
              <RelatedContentCardFlexBig
                imageName="code"
                title="Analytics"
                link="/de/google-analytics-consultant"
                description="Wir können uns nicht verbesseren, wenn wir nicht messen was auf der Webseite passiert."
              />
              <RelatedContentCardFlexBig
                imageName="desk"
                title="SEO"
                link="/de/seo-freelancer"
                description="Alles aus einer Hand: On-Page SEO, technische Beratung und Off-Page SEO."
              />
              <RelatedContentCardFlexBig
                imageName="coworking"
                title="Tag Management"
                link="/de/google-tag-manager-consultant"
                description="Ich kann Dir helfen automatisierte Dienste basierend auf deinen Daten zu integrieren."
              />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(Services);
